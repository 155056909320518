import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import theme from '../styles/theme'

const BlogImg = styled.div`
  img {
    width: 320px;
  }
`;

const Card = ({ slug, heroImage, title, publishDate, content, topics, body, ...props }) => {
  const index = props.index > 0 ? "mt-50" : ""
  return (
    <div className={`blog-post clearfix ${index}`}>
			<BlogImg className="thumb pull-left mr-40">
        <Link to={`/blog/${slug}/`}>
          {/* <Img fluid={heroImage.fluid}  /> */}
          <img src={heroImage.file.url} alt={heroImage.title} width="320"/>
        </Link>
      </BlogImg>
      <div className="blog-text pt-25">
        <Link to={`/blog/${slug}/`}><h3>{title}</h3></Link>
        <h6>{publishDate}</h6>
        <p dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.excerpt,
          }}
        />
        <Link className="read-more mt-25" to={`/blog/${slug}/`}>read more</Link>
      </div>
    </div>
  )
}

export default Card
