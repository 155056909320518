import React from 'react'
import styled from 'styled-components'

import BlogSideBar from '../components/BlogSideBar'

const CardList = props => {
  return (
    <div className="blog-area style1 white-bg pt-40 pb-120 clearfix">
      <div className="container">
        <div className="row row-eq-height">
          <div className="col-xs-12 col-sm-8 col-md-9 mobi-mb-50">
            {props.children}
          </div>
          <div className="col-xs-12 col-sm-4 col-md-3">
            <BlogSideBar />
          </div>
        </div>
      </div>
    </div> 
  )
}

export default CardList
