import React from 'react'
import { graphql } from 'gatsby'
import sortBy from 'lodash/sortBy'
import Layout from '../components/Layout'
import SimpleHero from '../components/SimpleHero'
import BlogCardListV2 from '../components/BlogCardListV2'
import BlogCardV2 from '../components/BlogCardV2'
import { Helmet } from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const TopicTemplate = ({ data, pageContext }) => {
  const posts = sortBy(data.contentfulTopic.post, 'publishDate').reverse()
  const { title, slug } = data.contentfulTopic
  const numberOfPosts = posts.length
  const skip = pageContext.skip
  const limit = pageContext.limit
  const currentPage = pageContext.currentPage
  const isFirstPage = currentPage === 1
  const seo = {
    pageTitle: 'Blog',
    shareImage: {
      ogimg: {
        src: posts[0].heroImage.ogimg.src,
        width: posts[0].heroImage.ogimg.width,
        height: posts[0].heroImage.ogimg.height,
      },
    },
    metaDescription: {
      metaDescription: "KPC's Blog",
    },
    metaKeywords: {
      metaKeywords: 'KPC Blog',
    },
  }

  return (
    <Layout>
      {seo && <SEO pagePath={`${pageContext.slug}`} postNode={seo} pageSEO />}
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
        </Helmet>
      )}
      <Container>
        {/* <SimpleHero headline={headline} pageName={pageName} /> */}
        <SimpleHero
          headline={`${numberOfPosts} Posts in topic: "${title}"`}
          secondaryURL="/blog/"
          secondaryName="Blog"
          pageName={title}
        />
        <BlogCardListV2>
          {posts.map((post, i) => {
            return <BlogCardV2 key={post.id} {...post} index={i} />
          })}
        </BlogCardListV2>
        <Pagination context={pageContext} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulTopic(slug: { eq: $slug }) {
      title
      id
      slug
      post {
        id
        title
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_noBase64
          }
          file {
            url
          }
          ogimg: resize(width: 1800) {
            src
            width
            height
          }
        }
        body {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 80)
          }
        }
      }
    }
  }
`

export default TopicTemplate
